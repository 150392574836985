import { rest } from 'msw';

const timeStamp = Date.now();
const startEndAvailable = {
	start: new Date(timeStamp-1000*60*60),
	end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
};

const startEndVisible = {
	start: new Date(timeStamp * 60 * 60),
	end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
};

const DEFAULT_PROMO = {
	title: '',
	available: startEndAvailable,
	weight: 1,
	visible: startEndVisible,
	max: 0,
};
const DEFAULT_1_PROMO = {
	trigger: { num: 2 },
	target: { id: 0, num: 7 },
};
const DEFAULT_3_PROMO = {
	trigger: { num: 1 },
	target: { num: 1 },
};
const DEFAULT_5_PROMO = {
	trigger: { num: 1 },
	target: { id: 0, num: 3 },
};

const TYPE_DEFAULTS_MAP = {
	1: DEFAULT_1_PROMO,
	3: DEFAULT_3_PROMO,
	5: DEFAULT_5_PROMO,
};

const generateMockPromo = (id, type, push, opts) => {
	const defaultTrigger = TYPE_DEFAULTS_MAP[type].trigger;
	const defaultTarget = TYPE_DEFAULTS_MAP[type].target;

	return {
		[id]: {
			...DEFAULT_PROMO,
			type,
			id,
			push,
			trigger: {
				...defaultTrigger,
				...(opts.trigger || {})
			},
			target: {
				...defaultTarget,
				...(opts.target || {})
			},
		},
	};
};

export const getProductPoints = (url) => [
	rest.get(`${url}/api/v1/products/points`, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				259: [
					{
						points: 10,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					},
					{
						points: 12,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: true,
					},
					{
						points: 8,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					}
				],
				1961: [
					{
						points: 10,
						date: {
							start: new Date(timeStamp-1000*60*60*40),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000*4000),
						},
						tradeFair: false,
					},
					{
						points: 12,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: true,
					},
					{
						points: 8,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					}
				],
				4676: [
					{
						points: 102,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					},
					{
						points: 82,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: true,
					}
				],
				1962: [
					{
						points: 102,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					},
					{
						points: 82,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: true,
					}
				],
				3: [
					{
						points: 10,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: false,
					},
					{
						points: 200,
						date: {
							start: new Date(timeStamp-1000*60*60),
							end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
						},
						tradeFair: true,
					}
				]
			}),
		);
	}),
];
