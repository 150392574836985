import React from 'react';
import './productsSummary.scss';
import { ProductSummaryArticle } from './ProductSummaryArticle';
import { PreOrderAction } from '~/types/preOrders';


// TODO: Fix typings
export interface ProductSummaryProps {
    products: any[];
    updateProductQuantity?: (...any: any) => void;
    removeProduct?: (...any: any) => void;
    confirmMadQuantity?: (...any: any) => void;
    promos?: any[];
    deliveryDate?: string | number | Date | null;
    quantities?: PreOrderAction['quantities'];
    issues?: any[],
    points?: any;
}
// TODO:
const ProductsSummary = (props: ProductSummaryProps) => {
    const {
        products, quantities, issues, points, updateProductQuantity, removeProduct, confirmMadQuantity
    } = props;
    const onQuantityChange = (quantity: { quantity: string | number }, product: any) => {
        if ((product.quantity === 0 && quantity.quantity === '') || Number(quantity.quantity) < 0) return;

        updateProductQuantity?.(product, quantity, product.promo);
    };

    const canUpdateQuantity = () => typeof updateProductQuantity === 'function';
    const canShowMadQuantityWarning = () => typeof confirmMadQuantity === 'function';

    return (
        <div className={'ProductsSummary'}>
            {products?.map((product, index) => <ProductSummaryArticle
                key={index}
                product={product}
                quantities={quantities}
                points={points}
                issues={issues}
                removeProduct={removeProduct}
                confirmMadQuantity={confirmMadQuantity}
                canUpdateQuantity={canUpdateQuantity}
                canShowMadQuantityWarning={canShowMadQuantityWarning}
                onQuantityChange={onQuantityChange}
            />)}
        </div>
    );
};

export default ProductsSummary;
