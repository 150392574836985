import React, { useMemo } from 'react';
import { Button } from '@abm-international/react-components';
import { BEURS_2024_PREORDER_TYPE } from './../../api/tradeFair/useBooths';
import { useAppDispatch, useAppSelector } from './../../libraries/hooks/redux';
import { submitAllPreOrdersByType } from '../PreOrders/preOrdersActions';
import {
	selectPreOrdersActions,
	selectIsSubmittingAllPreOrdersByType,
	selectDidSaveAllFail
} from '../selectors';
import './TradeFairSummary.scss';
import { CharlesTitle } from '@abm-international/react-components';
import { PreOrderAction } from '~/types/preOrders';
import ProductsSummary from './../../components/productsViews/productsSummary/productsSummary';
import dateHelper, { getDateOfISOWeek } from './../../libraries/dateHelper';
import { t } from './../../libraries/i18n';
import { Spinner } from '@abm-international/react-components';
import promoTypes from '../products/promoTypes';
import { usePoints } from '../../libraries/hooks/usePoints';
import { tradeFairDates } from '../../constants';
import { FaInfoCircle } from 'react-icons/fa';

type SummaryWeek = {
	id: PreOrderAction['delivery'];
	articles: any[];
	quantities: PreOrderAction['quantities'];
	weekNr: number;
	year: number;
	points?: number;
};

const getAwardedPromo = (article: any, week: any) => {
	if (!article.promo) return undefined;

	const quantity = week.quantities?.[article.id];
	if (!quantity || quantity === 0) return undefined;

	if (quantity < article.promo.trigger.num) return undefined;

	if (article.promo.type !== promoTypes.FREE_BY_UNIT) return {};

	const freeItems = Math.floor(quantity / article.promo.trigger.num) * article.promo.target.num;

	return {
		quantity: freeItems
	};
};

export default function TradeFairSummary() {
	const dispatch = useAppDispatch();
	const preOrdersActions = useAppSelector(state => selectPreOrdersActions(state, BEURS_2024_PREORDER_TYPE));
	const isSubmittingAllOrders = useAppSelector(state => selectIsSubmittingAllPreOrdersByType(state, BEURS_2024_PREORDER_TYPE));
	const didFail = useAppSelector(state => selectDidSaveAllFail(state, BEURS_2024_PREORDER_TYPE));

	const { calculateTotalPoints } = usePoints(tradeFairDates);

	const submitTradeFair = () => dispatch(submitAllPreOrdersByType(BEURS_2024_PREORDER_TYPE));

	const productsByWeek = useMemo(() => {
		return preOrdersActions
			.reduce((weeks: Array<SummaryWeek>, action) => {
				const week = weeks.find(w => w.id === action.delivery) ?? {
					id: action.delivery,
					weekNr: parseInt(action.delivery.split('.')[1]),
					year: parseInt(action.delivery.split('.')[0]),
					articles: [],
					quantities: {}
				};

				week.quantities = {
					...week.quantities,
					...action.quantities
				};

				week.articles = [
					...week.articles,
					...action.articles
						.filter(article => week.quantities?.[article.id] !== 0)
						.map(article => ({
							...article,
							// TODO: Check it. Does this work correctly?
							// Checked it, and it does not
							awardedPromo: getAwardedPromo(article, week) || 0,
							quantity: week.quantities?.[article.id],
							points: calculateTotalPoints([article], week.quantities, dateHelper.getDateOfISOWeek(week.weekNr, week.year))
						}))
				];

				return [
					...weeks.filter(w => w.id !== week.id),
					week
				];
			}, [])
			.sort((a, b) => a.id.localeCompare(b.id));
	}, [preOrdersActions]);

	const totals = productsByWeek.reduce<{ items: number; points: number }>((acc, week) => {
		const total = (week?.articles || []).reduce<{ items: any; points: any }>((ac, article) => {
			return {
				items: ac.items + article.quantity,
				points: ac.points + article.points.standardPoints + article.points.tradeFairPoints,
			};
		}, {
			items: 0,
			points: 0
		});
		// const total = week.articles.reduce<{items: number; points: number;}>((ac, article: any) => {
		// 	return {
		// 		items: ac.items + 0,
		// 		points: ac.points + 0,
		// 	};
		// }, { items: 0, points: 0 });

		return {
			items: acc.items + total.items,
			points: acc.points + total.points,
		};
	}, { items: 0, points: 0 });

	return (
		<div className='TradeFairSummary'>
			<CharlesTitle alt>{t('tradefair.summaryTitle')}</CharlesTitle>

			<div className='info'>
				{t('tradefair.summaryInfoLabel')}
			</div>

			{/* <div className='tabs'>
				<Button secondary color='gold'>
					Per week
				</Button>

				<Button secondary color='gold'>
					Per stand
				</Button>
			</div> */}

			<div className='summary-products'>
				{productsByWeek.map(week => (
					<div className='summary-section' key={week.weekNr}>
						<div className='title'>
							{t('tradefair.weekLabel', week.weekNr)}
						</div>
						{week.articles.length === 0 && (
							<div className='no-products'>
								{t('tradefair.noProductsForThisWeekLabel')}
							</div>
						)}
						{week.articles.length !== 0 && (
							<ProductsSummary
								products={week.articles.map((a: any) => ({ ...a, eob: false }))}
								promos={week.articles.map((a: any) => a.promo)}
								deliveryDate={getDateOfISOWeek(week.weekNr, week.year)}
								quantities={week.quantities}
								points={week.points}
							/>
						)}
					</div>
				))}
			</div>

			<div className='actions'>
				<Button primary onClick={submitTradeFair}>
					{!isSubmittingAllOrders && t('tradefair.submitOrder')}
					{isSubmittingAllOrders && (
						<Spinner size='small' />
					)}
				</Button>



				{didFail && (
					<p className='notification error'>Er is iets fout gegaan bij het opslaan. Probeer het later opnieuw.</p>
				)}

				{isSubmittingAllOrders && (
					<p className='notification'>{t('tradefair.thisCanTakeAWhile')}</p>
				)}

				<div className='totals'>
					<span className='quantity__total'>{totals.items} <span>{totals.items === 1 ? t('order.reviewUnitLabel') : t('order.reviewUnitLabelMultiple')}</span></span>
					<span className='quantity__total'>{totals.points} <span>{totals.points === 1 ? 'point' : 'points'}</span></span>
				</div>
			</div>

			<span className='disclaimer'>
				<div>
					<FaInfoCircle />
				</div>
				{t('tradefair.disclaimer')}
			</span>
		</div >
	);
}
