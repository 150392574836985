
import { FaInfoCircle } from 'react-icons/fa';
import { t } from '../../libraries/i18n';

export default function Disclaimer() {
    return (
        <div className='Disclaimer' style={{
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            paddingLeft: '0.5rem',
            color: 'rgba(0, 0, 0, 0.5)',
        }} onClick={
            () => {
                alert(t('tradefair.disclaimer'));
            }
        }>
            <FaInfoCircle />
        </div>
    );
}
