import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectActivePromoByProductId, selectAwardedPromoByProductIdForPreOrder, selectPreOrderActionQuantities,
	selectProductById,
	selectProductPointsById
} from '../../selectors';
import { awardPromoIfEligible, updatePreOrderActionArticleQuantity } from '../../PreOrders/preOrdersActions';
import QuantityInput from '../CommonQuantityInput';
import { OrderArticle } from '@abm-international/react-components';
import { useProductPoints } from '../../../libraries/hooks/useProductPoints';
import { tradeFairDates } from '../../../constants';


function PreOrderArticleContainer(props) {
	const {
		product: { id }, product, group, preOrderType, action, deliveryDate
	} = props;
	const dispatch = useDispatch();
	const _product = useSelector(state => selectProductById(state, id, true));

	const quantities = useSelector(state => selectPreOrderActionQuantities(state, group, preOrderType));
	const promo = useSelector(state => selectActivePromoByProductId(state, id, deliveryDate));
	const awardedPromo = useSelector(state => selectAwardedPromoByProductIdForPreOrder(state, preOrderType, action.group, product.id));

	const updateProductQuantity = useCallback(({ quantity }) => {
		dispatch(updatePreOrderActionArticleQuantity(action, product, quantity, preOrderType));
		dispatch(awardPromoIfEligible(product, quantity, promo, preOrderType, action.group));
	}, [dispatch, action, product, preOrderType, promo]);

	const points = useSelector(state => selectProductPointsById(state, id));
	const productPoints = useProductPoints(
		points,
		deliveryDate,
		tradeFairDates,
	);

	if (!_product) return null;

	return (
		<OrderArticle
			{...props}
			awardedPromo={awardedPromo}
			quantity={quantities?.[id]}
			QuantityInput={QuantityInput}
			updateProductQuantity={updateProductQuantity}
			product={{
				..._product,
				...product,
				promo: promo ? {
					...promo,
					target: {
						...promo.target,
						product: product.promo?.target?.article
					},
					trigger: {
						...promo.trigger,
						product: product.promo?.trigger?.article
					}
				} : null
			}}
			points={{
				normal: productPoints.standardPoints,
				tradeFair: productPoints.tradeFairPoints
			}}
		/>
	);
}

export default PreOrderArticleContainer;
