import React, {
	useMemo,
	useRef,
	useState
} from 'react';
import './TradeFairArticle.scss';
import { Article } from '@abm-international/react-components';
import { useSelector } from 'react-redux';
import { t } from '../../libraries/i18n';
import type { Article as ArticleType } from '../../types/articles';
import {
	selectActivePromoByProductId, selectIsProductHistoriesFetching, selectLocale, selectOrderProducts, selectPreOrderActionAwardedPromo, selectProductById, selectProductHistories, selectProductPointsById
} from '../selectors';
import { VIEWS } from '../common/OrderArticle';
import { FaTruck } from 'react-icons/fa';
import { Badge } from '@abm-international/react-components';
import { PreOrderAction } from '~/types/preOrders';
import { BEURS_2024_PREORDER_TYPE } from './../../api/tradeFair/useBooths';
import { useProductPoints } from '../../libraries/hooks/useProductPoints';
import { tradeFairDates, tradeFairWeeks } from '../../constants';
import { getDateOfISOWeek } from '../../libraries/dateHelper';
import { ArticlePromo } from '@abm-international/react-components';
import { Coin } from '@abm-international/react-components';
import Modal from '../../components/modal/modal';
import { useAppSelector } from '../../libraries/hooks/redux';
import ProductDetail from '../PreOrders/ProductItem/ProductDetail';
import { CommonQuantityInput } from '@abm-international/react-components';

const WEEKS = tradeFairWeeks;


type ArticleWeekProps = {
	article: ArticleType;
	isFocused?: string;
	setIsFocused: (id?: string) => void,
	onQuantityChange: (quantity: any, action: PreOrderAction) => void;
	wId: string;
	week?: any;
	deliveryDate: Date;
}

const ArticleWeek = (props: ArticleWeekProps) => {
	const {
		article,
		isFocused,
		setIsFocused,
		onQuantityChange,
		wId,
		week,
		deliveryDate
	} = props;


	const awardedPromo = useSelector(state => selectPreOrderActionAwardedPromo(state, week?.action?.group, article.id, article?.promo, BEURS_2024_PREORDER_TYPE));
	const points = useSelector(state => selectProductPointsById(state, article.id));
	const productPoints = useProductPoints(
		points,
		deliveryDate,
		tradeFairDates,
	);

	return (
		<div className='week-container'>
			<div className='week-number'>
				{t('tradefair.weekLabel', wId.split('.')[1])}
			</div>
			<div className={`week${isFocused === wId ? ' focus' : ''}${week ? '' : ' unavailable'}${week?.quantity ? ' has-quantity' : ''}`} key={wId}>
				{week && <>
					<div className="upper">

						<CommonQuantityInput
							quantity={week.quantity}
							awardedPromo={typeof awardedPromo !== 'boolean' ? awardedPromo.quantity : awardedPromo}
							onQuantityChange={(e: { quantity: number }) => onQuantityChange(e.quantity, week.action)}
							orderAmount={article.orderAmount}
							focus={false}
							t={t}
							size='compact'
							onFocus={() => setIsFocused(wId)}
							onBlur={() => setIsFocused(undefined)}
						/>
					</div>
					<div className='coins'>
						<Coin points={[productPoints.standardPoints]} size='small' />
						<Coin koerse points={[productPoints.tradeFairPoints]} size='small' />
					</div>

					<div className='info'>
						{week.orderedQuantity > 0 && (
							<Badge
								secondary={`${week.orderedQuantity}${week?.freeQuantity ? `+${week.freeQuantity}` : ''}`}
								className={'pending'}
								color={'#373735'}
							>
								<FaTruck />
							</Badge>
						)}

						{article.promo && (<>
							<ArticlePromo
								promo={article.promo}
								productId={article.id}
								deliveryDate={deliveryDate}
								t={t}
							// view={'detail'}
							/>
						</>
						)}
					</div>
				</>}
			</div>
		</div>
	);
};

type Props = {
	article: ArticleType,
	updateQuantity: (action: PreOrderAction, article: ArticleType, quantity: number) => void,
	scrollPosition: number,
	updateScrollPosition: (position: number) => void,
	deliveryWeek: string;
};

export default function TradeFairArticle(props: Props) {
	const {
		article,
		deliveryWeek
	} = props;

	const orderArticles = useAppSelector(selectOrderProducts);
	const locale: 'nl' | 'fr' = useSelector(selectLocale);
	const [isFocused, setIsFocused] = useState<string | undefined>(undefined);
	const weeksRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const product = useAppSelector(state => selectProductById(state, article.id));

	const [showModal, setShowModal] = useState<undefined | string>();

	const modalProduct = useMemo(() => {
		if (!showModal) return undefined;

		if (!Array.isArray(orderArticles)) return { ...product, ...article };
		const orderArticle = orderArticles.find((a: any) => a.id === showModal);
		if (!orderArticle) return { ...product, ...article };

		const articleWithPrice = {
			...product,
			...article,
			price: orderArticle?.price,
			unit: orderArticle?.unit
		};

		return articleWithPrice;
	}, [article, showModal, orderArticles, product]);

	const onQuantityChange = (quantity: any, action: PreOrderAction) => {
		if (quantity < 0 || (article.quantity === 0 && quantity === '')) return;

		props.updateQuantity(action, article, quantity);
	};

	const deliveryDate = getDateOfISOWeek(deliveryWeek.split('.')[1], deliveryWeek.split('.')[0]);
	const promo = useSelector(state => selectActivePromoByProductId(state, article.id, deliveryDate));

	const histories = useSelector(selectProductHistories);
	const isHistoryFetching = useSelector(selectIsProductHistoriesFetching);

	if (!product) {
		return null;
	}

	return (
		<>
			<Modal show={showModal !== undefined} close={() => setShowModal(undefined)}>
				<ProductDetail product={{ ...modalProduct, eob: false, promo: promo }} deliveryDate={deliveryDate} showTradeFairPrice />
			</Modal>

			<Article
				product={{ ...product, ...article, promo }}
				t={t}
				view={VIEWS.COMPACT}
				locale={locale}
				imageSource={`${process.env.REACT_APP_PORTAL_IMAGE_URL}/resources/images/articles`}
				showModal={showModal === article.id}
				history={histories[article.id]}
				isHistoryFetching={isHistoryFetching}
			>
				{(A: any) => (
					<div className={`TradeFairArticle ${VIEWS.COMPACT}`}>
						<div className='left'>
							<A.Image onClick={() => setShowModal(article.id)} />
							<div className='text'>
								<div className={'title'} onClick={() => setShowModal(article.id)}>
									<A.BuylistIndicator />
									<A.Id />
									<A.Name />
									{/* <A.Badges /> */}
								</div>
								<A.Allergens
									compact={true}
									view={''}
								/>
								<A.OrderHistory
									size='compact'
									history={histories[article.id]}
									isFetching={isHistoryFetching}
									t={t}
									maxWeeks={7}
								/>
							</div>

						</div>

						<div className='weeks' ref={weeksRef}>
							{WEEKS.map(wId => {
								const week = article.weeks.find((weekItem: any) => weekItem.week === wId);

								return (
									<ArticleWeek
										key={wId}
										article={{ ...product, ...article, promo }}
										setIsFocused={setIsFocused}
										isFocused={isFocused}
										onQuantityChange={onQuantityChange}
										wId={wId}
										week={week}
										deliveryDate={deliveryDate}
									/>
								);
							})}
						</div>
					</div>
				)}
			</Article>
		</>
	);
}
