import { rest } from 'msw';

const timeStamp = Date.now();
const startEndAvailable = {
	start: new Date(timeStamp-1000*60*60),
	end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
};

const startEndVisible = {
	start: new Date(timeStamp * 60 * 60),
	end: new Date(timeStamp + 1000 * 60 * 60 * 24 * 7*10000),
};

const DEFAULT_PROMO = {
	title: '',
	available: startEndAvailable,
	weight: 1,
	visible: startEndVisible,
	max: 0,
};
const DEFAULT_1_PROMO = {
	trigger: { num: 2 },
	target: { id: 0, num: 7 },
};
const DEFAULT_3_PROMO = {
	trigger: { num: 1 },
	target: { num: 1 },
};
const DEFAULT_5_PROMO = {
	trigger: { num: 1 },
	target: { id: 0, num: 3 },
};

const TYPE_DEFAULTS_MAP = {
	1: DEFAULT_1_PROMO,
	3: DEFAULT_3_PROMO,
	5: DEFAULT_5_PROMO,
};

const generateMockPromo = (id, type, push, opts) => {
	const defaultTrigger = TYPE_DEFAULTS_MAP[type].trigger;
	const defaultTarget = TYPE_DEFAULTS_MAP[type].target;

	return {
		[id]: {
			...DEFAULT_PROMO,
			type,
			id,
			push,
			trigger: {
				...defaultTrigger,
				...(opts.trigger || {})
			},
			target: {
				...defaultTarget,
				...(opts.target || {})
			},
		},
	};
};

export const getProductPromos = (url) => [
	rest.get(`${url}/api/products/promos`, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				// Type 1 promos
				//...generateMockPromo('1', 1, 0, { trigger: { id: 1, num: 1 } }),
				...generateMockPromo('10', 1, 0, { trigger: { id: 10, num: 1 } }),
				...generateMockPromo('1030', 1, 1, { trigger: { id: 1030 } }),
				...generateMockPromo('1080', 1, 0, { trigger: { id: 1080 } }),
				...generateMockPromo('521051', 1, 0, { trigger: { id: 31703, num: 1 } }),
				// Type 1 promos (in buylist)
				...generateMockPromo('5193536', 1, 1, { trigger: { id: 14 }, target: { num: 20 } }),
				...generateMockPromo('5193574', 1, 0, { trigger: { id: 111 }, target: { num: 20 } }),
				// Type 1 promos (outside buylist)
				...generateMockPromo('519356', 1, 1, { trigger: { id: 8011 }, target: { num: 20 } }),
				...generateMockPromo('519357', 1, 0, { trigger: { id: 8024 }, target: { num: 20 } }),
				// Type 3 promos (in buylist)
				...generateMockPromo('520457', 3, 0, { trigger: 140 }, { target: { id: 20 } }),
				...generateMockPromo('520458', 3, 0, { trigger: 139 }, { target: { id: 75 } }),
				// Type 3 promos (outside buylist)
				...generateMockPromo('520457', 3, 0, { trigger: 140 }, { target: { id: 74 } }),
				...generateMockPromo('520458', 3, 0, { trigger: 139 }, { target: { id: 87 } }),
				// Type 5 promos (in buylist)
				...generateMockPromo('520509', 5, 1, { trigger: { id: 124 } }),
				...generateMockPromo('520510', 5, 0, { trigger: { id: 130 } }),
				// Type 5 promos (outside buylist)
				...generateMockPromo('520509', 5, 1, { trigger: { id: 18 } }),
				...generateMockPromo('520510', 5, 0, { trigger: { id: 19 } }),
				// Barrel promos
				'2209192209250623': {
					type: 1,
					id: '2209192209250623',
					title: '',
					push: 1,
					available: startEndAvailable,
					weight: 1,
					visible: startEndVisible,
					trigger: {
						barrel: {
							code: '0623',
							num: 2,
							products: [
								11,
								49
							]
						},
						num: 3
					},
					target: {
						id: 0,
						num: 25
					}
				},
				'538112': {
					'type': 3,
					'id': 538112,
					'title': '',
					'push': 1,
					'available': {
						'start': '2022-06-23T22:00:00+00:00',
						'end': '2028-06-30T21:59:59+00:00'
					},
					'weight': 1,
					'visible': {
						'start': '2022-03-15T23:00:00+00:00',
						'end': '2028-06-30T21:59:59+00:00'
					},
					'trigger': {
						'id': 1961,
						'num': 3
					},
					'target': {
						'id': 1961,
						'num': 1
					},
					'max': 0
				},
				'5340773': {
					'type': 3,
					'id': 5340773,
					'title': '',
					'push': 0,
					'available': {
						'start': '2024-03-24T23:00:00+00:00',
						'end': '2026-04-07T21:59:59+00:00'
					},
					'weight': 1,
					'visible': {
						'start': '2023-12-15T23:00:00+00:00',
						'end': '2026-04-07T21:59:59+00:00'
					},
					'trigger': {
						'id': 259,
						'num': 2
					},
					'target': {
						'id': 259,
						'num': 1
					},
					'max': 0
				},
				'534077': {
					'type': 3,
					'id': 534077,
					'title': '',
					'push': 0,
					'available': {
						'start': '2024-03-24T23:00:00+00:00',
						'end': '2024-04-07T21:59:59+00:00'
					},
					'weight': 1,
					'visible': {
						'start': '2023-12-15T23:00:00+00:00',
						'end': '2024-04-07T21:59:59+00:00'
					},
					'trigger': {
						'id': 1630,
						'num': 2
					},
					'target': {
						'id': 435,
						'num': 1
					},
					'max': 0
				},
				'527412': {
					'type': 3,
					'id': 527412,
					'title': '',
					'push': 1,
					'available': {
						'start': '2023-05-28T22:00:00+00:00',
						'end': '2025-06-04T21:59:59+00:00'
					},
					'weight': 1,
					'visible': {
						'start': '2023-05-28T22:00:00+00:00',
						'end': '2025-06-04T21:59:59+00:00'
					},
					'trigger': {
						'id': 1,
						'num': 1
					},
					'target': {
						'id': 1,
						'num': 1
					},
					'max': 0
				}
				,
				'2209192209250624': {
					type: 1,
					id: '2209192209250624',
					title: '',
					push: 0,
					available: startEndAvailable,
					weight: 1,
					visible: startEndVisible,
					trigger: {
						barrel: {
							code: '0623',
							num: 1,
							products: [
								15,
								32429
							]
						},
						num: 2
					},
					target: {
						id: 0,
						num: 25
					}
				},
			}),
		);
	}),
];
